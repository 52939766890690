.theme-default1 {
    --color-background-primary: #f7fff7;
    --color-background-toolbar: #2d3e50;
    --color-background-list: #2c3e50;
    --color-text-primary: #192734;
    --color-text-toolbar: #e0f7f5; 
    --color-text-list: #e0f5f1;
    --color-bright: #4ecdc4;
    --color-transparent: transparent;
  }
  
  /* Midnight Lagoon */
  .theme-midnight-lagoon {
    --color-background-primary: #192734;
    --color-background-toolbar: #22303c;
    --color-background-list: #2c3e50;
    --color-text-primary: #192734;
    --color-text-toolbar: #f0ffff; 
    --color-text-list: #e0f7f5;
    --color-bright: #5ce0d8;
    --color-transparent: transparent;
  }
  
  /* Twilight Reef */
  .theme-twilight-reef {
    --color-background-primary: #15202b;
    --color-background-toolbar: #253341;
    --color-background-list: #6dcdc7;
    --color-text-primary: #f5ffff;
    --color-text-toolbar: #e5f9f7; 
    --color-text-list: #e0f7f5;
    --color-bright: #6dcdc7;
    --color-transparent: transparent;
  }
  
  /* Dark Mocha Leaf */
  .theme-dark-mocha-leaf {
    --color-background-primary: #1f1d1d;
    --color-background-toolbar: #8d6a60;
    --color-background-list: #f8f5f2;
    --color-text-primary: #1f1d1d;
    --color-text-toolbar: #2a2727; 
    --color-text-list: #2a2727;
    --color-bright: #00b472;
    --color-transparent: transparent;
  }
  
  /* Ocean Breeze */
  .theme-ocean-breeze {
    --color-background-primary: #f7fff7;
    --color-background-toolbar: #2d3e50;
    --color-background-card: #3e525e;
    --color-background-list: #e0f5f1;
    --color-text-primary: #2d3e50;
    --color-text-toolbar: white; 
    --color-text-list: #2d3e50;
    --color-text-card: #e0f5f1;
    --color-bright: #4ecdc4;
    --color-transparent: transparent;
    --ion-item-border-color: white; 
    --color-backgrount-toolbar-secondary: #f8f9fa;
    --color-backgrount-toolbar-secondary: #e0f5f1;
  }
  
  /* .theme-default {
    --color-background-primary: transparent;
    --color-background-toolbar: rgba(0,0,0,0.5);
    --color-background-list: #f8f9fa;
    --color-background-addons: #4C9C9F;
    --color-text-primary: #000;
    --color-text-toolbar: #f7fff7; 
    --color-text-list: #000;
    --color-bright: #5CE0D8;
    --color-transparent: transparent;
    --color-background-card: rgba(0,0,0,0.5);
  } */
  
  .theme-default {
    --color-background-primary: transparent;
    --color-background-toolbar: rgba(0,0,0,0.5);
    --color-background-list: #f8f9fa;
    --color-background-addons: #4C9C9F;
    --color-text-primary: #000;
    --color-text-toolbar: #feffff; 
    --color-text-list: #000;
    --color-bright: #5CE0D8;
    --color-transparent: transparent;
    --color-background-card: rgba(0,0,0,0.5);
  }