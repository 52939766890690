.custom-swiper-container {
  height: 100vh;
  /* width: 100%; */
  /* touch-action: none;  */
}

.swiper {
  height: 100%;
  width: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-vertical {
  height: 100%;
}