.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.mr-2 {
  margin-right: 5px;
}

.justify-content-between {
  justify-content: space-between;
}

.spinning {
  animation: spin 1s linear infinite;
}

.relative {
  position: relative;
}

.f-bold {
  font-weight: bold !important;
}

.f-15 {
  font-size: 15px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.words-container {
  max-height: 150px;
  overflow-y: auto;
  margin-bottom: 10px;
}

.card-actions {
  display: flex;
  margin-top: 10px;
}

.app-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: var(--color-background-primary); */
  background: url("../../public/assets/bcg.png");
  opacity: 0.8;
  z-index: -1;
}

.app-logo {
  background-image: url("../../public/assets/app-logo.jpg");
  background-size: cover;
  background-position: center;
  opacity: 0.8;
}

.app-name {
  font-family: "Qwitcher Grypen", cursive;
  font-weight: 700;
  color: cyan;
  font-size: 40px;
  transform: rotate(-7deg);
  padding: 0;
  margin-bottom: 16px;
}

.app-content {
  height: 100%;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

ion-content::part(background) {
  background: var(--color-background-primary);
}

.main-title {
  font-family: 'Rowdies', sans-serif;
  background: var(--gradient-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  margin: 8px 0 0 0;
}

ion-header {
  background: var(--color-background-toolbar);
  color: var(--color-text-toolbar);
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 65px;
}

ion-header ion-title {
  font-size: 22px;
  /* text-shadow: var(--color-bright) 2px 3px 5px; */
  padding-top: 8px;
}

ion-header ion-button::part(native) {
  color: var(--color-bright);
  background: transparent;
}

/* 
.header-wrapper.mobile-view {
  height: 130px;
} */

.search-wrapper.mobile-view {
  top: 80px;
  width: 100%;
  right: 0;
}

.footer {
  /* border-radius: 40px;
  width: 90%;
  margin: 12px auto 11px auto;
  padding: 0; */
  box-shadow: 0 0 10px var(--color-bright);
}

ion-toolbar,
ion-menu {
  --background: transparent;
  background: transparent;
}

.logo {
  height: 50px;
  margin-right: 5px;
}

.logo ion-button {
  --background: transparent;
  height: 100%;
  --padding-bottom: 8px;
  --padding-top: 0;
  --padding-start: 0;
  --padding-end: 0;
}

.logo ion-img {
  height: 100%;
}

.search-container {
  display: flex;
  align-items: center;
}

.search-wrapper {
  padding: 20px 20px 10px;

  /* position: absolute; */
  /* right: 125px; */
  ion-list {
    position: absolute;
    z-index: 100;
    width: calc(100% - 32px);
  }
}

.search-input {
  --background: var(--color-background-addons);
  --color: var(--color-text-toolbars);
  --placeholder-color: var(--color-text-toolbar);
  --icon-color: var(--color-text-toolbar);
  padding: 0;
  border-radius: 5px;
  /* --border-radius: 20px; */
  /* width: 50%; */
  /* min-width: 300px; */
  /* margin: 5px auto; */
}

.search-input .searchbar-input.sc-ion-searchbar-md {
  /* box-shadow: 0 0 10px var(--color-bright); */
  background: var(--color-background-addons);
  border-radius: inherit;

  &::placeholder {
    color: white;
  }
}

.search-input .searchbar-input.sc-ion-searchbar-md:hover,
.search-input .searchbar-input.sc-ion-searchbar-md:focus {
  /* border-color: var(--color-primary); */
  /* box-shadow: 0 0 15px var(--color-bright); */
}

.login-container,
.app-content-container {
  border-radius: 8px;
  min-height: 100%;
  color: var(--color-text-dark);
  max-width: 1140px;
  margin: 0 auto;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo-wrapper {
  align-items: center;
  cursor: pointer;
}

.login-logo-wrapper {
  height: 85px;
  margin: 25px 0;
}

.login-logo-wrapper ion-img {
  height: 100%;
}



.spotify-btn {
  --background: var(--color-spotify);
  --background-hover: var(--color-spotify-hover);
  --background-activated: var(--color-spotify-hover);
  --border-radius: 500px;
  --color: var(--color-text-primary);
  --padding-top: 14px;
  --padding-bottom: 14px;
  --padding-start: 32px;
  --padding-end: 32px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.spotify-btn::part(native) {
  text-shadow: var(--color-bright) 2px 3px 5px;
  font-family: "Google Sans", arial, sans-serif;
  font-weight: bold;
  transition: all 0.3s ease;
}

.spotify-btn:hover::part(native) {
  transform: scale(1.05);
}

.google-btn {
  --border-radius: 500px;
  --color: var(--color-google-text);
  --padding-top: 8px;
  --padding-bottom: 8px;
  --padding-start: 19px;
  --padding-end: 19px;
}

.google-btn::part(native) {
  font-family: "Google Sans", arial, sans-serif;
  font-weight: 500;
  text-transform: capitalize;
  font-weight: bold;
}

ion-modal.words-modal {
  --background: var(--color-overlay);
}

.modal-x-btn {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 35px;
  color: var(--color-background-primary);

  --padding-bottom: 0;
  --padding-top: 0;
  --padding-start: 0;
  --padding-end: 0;
  --border-radius: 50%;

  &::part(native) {
    background: var(--color-bright);
  }
}

.spotify-link-btn {
  height: 30px;
  --background: var(--color-spotify);
  --border-radius: 50%;
}

.spotify-link-btn ion-img {
  height: 100%;
}

.spotify-link-btn::part(native) {
  transition: all 0.3s ease;
}

.spotify-link-btn:hover::part(native) {
  transform: scale(1.05);
}

.app-content-container.coming-soon-page {
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-content-container.coming-soon-page div {
  border: 10px double var(--color-primary);
  padding: 10px 40px;
}

.app-content-container.coming-soon-page h1 {
  font-size: 2.3rem;
}

@media (min-width: 768px) {
  .hide-on-desktop {
    display: none !important;
  }
}

.tab-bar {
  --background: var(--color-background-toolbar);
}

/* Custom Tab Button Colors */
ion-tab-button {
  --color: var(--ion-color-medium);
  --color-selected: var(--color-bright);
}

ion-tab-button {
  --color-selected: var(--color-bright);
}

.now-playing-wrapper {
  position: fixed;
  bottom: 57px;
  background: var(--color-background-addons);
  color: var(--color-text-light);
  width: 100%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 1);

  .toggle-btn::part(native) {
    background: var(--color-primary);
  }

  .action-btn {
    position: absolute;
    height: 60px;
    width: 60px;
    --border-radius: 50%;
    top: -10px;
    left: 5px;
    z-index: 10;
    --box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
  }

  ion-item {
    --padding-start: 75px;
  }

  .toggle-btn {
    position: absolute;
    height: 60px;
    width: 60px;
    --border-radius: 50%;
    z-index: 10;
    top: -12px;
    --box-shadow: 0px 2px 7px rgba(0, 0, 0, 1);
    left: 8px;
  }
}



.song_data {
  background: var(--color-background-list);
  margin: 15px 0;
  border-radius: 5px;

  .sentence-translation-list {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }

  .sentence {
    font-size: 18px;
    color: #333;
    margin-bottom: 8px;
  }

  .translation {
    font-size: 16px;
    color: var(--color-text-list);
    font-style: italic;
  }

  @media (max-width: 768px) {
    .sentence-translation-list {
      padding: 10px;
    }

    .sentence-translation-item {
      padding: 10px;
    }

    .sentence {
      font-size: 16px;
    }

    .translation {
      font-size: 14px;
    }
  }
}

.welcome-container {
  height: calc(100vh - 30px);
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  color: white;
  padding: 20px;
  text-align: center;
  max-width: 800px;
  margin: auto;

  h1 {
    font-size: 2rem;
  }

  .welcome-section {
    max-width: 450px;
    margin: 0 auto 30px;
  }

  .welcome-section__label {
    font-size: 1.2rem;
    text-align: start;
  }
  .select-wrapper {
  justify-content: start;
  }
  ion-item {
    --background: var(--color-text-toolbar);
    --color: var(--color-text-list);
    --border-radius: 8px;
  }
}
.main-button {
  --background: #2b7a78;
}