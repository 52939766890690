.default-card {
    --background: transparent;
    --color: var(--color-text-primary);
    min-height: calc(100vh - 133px);
    box-shadow: none;
    max-width: 1140px;
    margin: 0 auto;
}

ion-thumbnail {
    margin-right: 12px;
    --border-radius: 5px;
    min-width: 55px;
    min-height: 55px;
}

.default-card .default-card__subtitle {
    margin-bottom: 20px;
}

.default-card .default-card-wrapper {
    padding: 15px;
    border-radius: 5px;
    background: var(--color-background-card);
    color: var(--color-text-card);
    box-shadow: 0px 0px 10px var(--color-bright);
    margin: 10px;
}

.default-card__list-wrapper {
    border-radius: 5px;
}

.default-card .default-card__list-title {
    margin: 0px 0px;
    font-size: 1.2rem;
    color: var(--color-bright);
    /* text-shadow: var(--color-bright) 0px 1px 3px; */
}

.default-card .default-card__content-right {
    width: 100%;
    padding: 8px;
}

@media (max-width: 768px) {
    .default-card .default-card__content-wrapper {
        width: 100%;
        height: calc(100vh - 240px);
    }

    .default-card .default-card__content-right {
        padding: 12px 0px;
    }
}

.default-card ion-list {
    border-radius: inherit;
    background: var(--color-background-list);
    margin: 20px 0;
}

.default-card ion-list ion-item {
    --padding-start: 15px;
    --padding-end: 15px;

    &::part(native) .item-inner {
        border-width: 2px;
    }
}

@media (max-width: 768px) {
    .default-card ion-list ion-item {
        --padding-start: 5px;
        --padding-end: 5px;
    }
}

.default-card__title {
    background: var(--gradient-primary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    cursor: pointer;
}

ion-item {
    --background: transparent;
    --color: var(--color-text-list);
}

.action-btns {
    position: absolute;
    right: 0;
    bottom: 2px;

    .action-btn {
        margin: 0 2px;
    }
}

.action-btn::part(native) {
    background-color: var(--color-bright);
    padding: 5px 5px;
    color: var(--color-text-toolbar);
}

.toggle-btn, .words-modal-btn {
    --color: var(--color-bright);
    border-radius: 5px;
    --padding-end: 10px;
    --padding-start: 10px;
}

 .word {
    flex: 0 0 auto;
    scroll-snap-align: start;
    margin-right: 10px;
    padding: 5px;
    background-color: var(--color-background-list);
    border-radius: 5px;
    white-space: nowrap;
    
  } 