.word-card {
  width: 100vw;
  height: 100vh;
  padding: 20px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-inline-end: 0;
  margin-inline-start: 0;
  border-radius: 0;
}
.word-card {
  height: 100vh;
  padding: 2rem;
  background-color: #f8f9fa;
}

.main-word {
  text-align: center;
  margin-bottom: 2rem;
}

.source-word {
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #2c3e50;
}

.translation {
  font-size: 2.5rem;
  color: #34495e;
  margin-bottom: 1rem;
}

.word-type {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 1.2rem;
  color: #7f8c8d;
}

.example-box {
  background-color: white;
  padding: 1.5rem;
  border-radius: 12px;
  margin-bottom: 2rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.example {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #2c3e50;
}

.example-translation {
  font-size: 1.25rem;
  color: #7f8c8d;
}

.actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.actions ion-button {
  --padding-start: 2rem;
  --padding-end: 2rem;
  font-size: 1.2rem;
}