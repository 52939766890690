/* 
  .word {
    flex: 0 0 auto;
    scroll-snap-align: start;
    margin-right: 10px;
    padding: 5px;
    background-color: #f0f0f0;
    border-radius: 5px;
    white-space: nowrap;
    
  }


 */

.fullscreen-modal {
  --width: 100% !important;
  --height: 100% !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.fullscreen-modal::part(content) {
  width: 100%;
  height: 100%;
}

.fullscreen-modal ion-content {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
}
.word {
  width: 100vw;
  height: 100vh;
  padding: 20px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-inline-end: 0;
  margin-inline-start: 0;
  border-radius: 0;
}
.close-modal-btn {
  position: absolute;
  z-index: 100;
}